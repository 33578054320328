<template>
  <div class="home">
    <Generateur/>
  </div>
</template>

<script>
import Generateur from '@/components/Generateur.vue';

export default {
  name: 'Home',
  components: {
    Generateur,
  },
};
</script>

<template>
  <div>
    <h1>Génération d'une carte de voeux</h1>
    <div class="board">
      <component
        :is="componentFile"
        :texteEnvoye="texte"
        :taille="0.5" />
    </div>
    <textarea
      :style="'width: 60%; resize:none;height: 100px;'"
      v-model="texte"
      placeholder="Écrivez votre texte ici">
    </textarea>
  </div>
  <div>
    <button
      class="btn first"
      @click="telecharger">Télécharger</button>
    <button
      v-if="lien.length == 0"
      id="boutonObtenirLien"
      v-bind:class="{ enCours: lienEnCours }"
      class="btn first"
      @click="obtenirLien">Obtenir le lien</button>
    <p
      v-if="lien.length > 0">
      Votre carte de voeux est accessible au lien suivant:<br/>
      <a :href="lien">{{ lien }}</a>
    </p>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
  defineAsyncComponent,
} from 'vue';
import axios from 'axios';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'Générateur',
  setup() {
    const texte = ref('');
    const lienEnCours = ref(false);
    const lien = ref('');
    const route = useRoute();
    // eslint-disable-next-line
    const componentFile = computed(() => defineAsyncComponent(() => import('@/components/' + route.params.annee + '/Visionneuse.vue')));

    function telecharger() {
      const link = document.createElement('a');
      link.download = 'Carte de voeux ST.png';
      link.href = document.getElementById('carte').toDataURL();
      link.click();
    }

    function obtenirLien() {
      if (lienEnCours.value) {
        console.log('Je suis déjà en train de chercher!');
        return false;
      }
      lienEnCours.value = true;

      axios.post(`${process.env.VUE_APP_API_URL}/textes/creer/`, {
        texte: texte.value,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          lien.value = `${process.env.VUE_APP_URL}${process.env.VUE_APP_PUBLICPATH}carte/${route.params.annee}/${response.data}`;
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          lienEnCours.value = false;
        });
      return true;
    }

    return {
      texte,
      telecharger,
      obtenirLien,
      lienEnCours,
      lien,
      componentFile,
    };
  },
});

</script>

<style scoped>
textarea {
  padding:10px;
  margin: 0 auto 10px;
  border: 1px solid #2d303a91 ;
  font-size:20px;
  font-family:'Montserrat Alternates';
  border-radius:10px;outline:none;
  transition: box-shadow .3s ease
}
textarea:-ms-input-placeholder {color: #139793}
textarea::-moz-placeholder {color: #139793}
textarea::-webkit-input-placeholder {color: #139793}

textarea:focus { border: 2px solid #139793 }
textarea:focus,
textarea:hover { box-shadow:0px 0px 13px 0px #139793 }
.btn {
  box-sizing: border-box;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  border: 2px solid #139793;
  border-radius: 0.6em;
  color: #139793;
  cursor: pointer;

  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  margin: 20px;
  padding: 1.2em 2.8em;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}
.btn:hover {
  color: #fff !important;
  outline: 0;
}

.first {
  -webkit-transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
}
.first:hover {
  box-shadow: 0 0 40px 40px #139793 inset;
}

@keyframes blink {
  from { opacity: 1.0; }
  50% { opacity: 0.3; }
  to { opacity: 1.0; }
}

.enCours {
  animation: blink 500ms infinite;
}

</style>

import { createRouter, createWebHistory } from 'vue-router';
import { nextTick } from 'vue';
import Home from '../views/Home.vue';

const routes = [
  {
    path: '/:annee',
    name: 'Home',
    component: Home,
  },
  {
    path: '/apropos',
    name: 'Apropos',
    component: () => import(/* webpackChunkName: "about" */ '../views/Apropos.vue'),
    meta: {
      title: 'À propos',
    },
  },
  {
    path: '/carte/:annee/:id',
    name: 'Carte',
    component: () => import(/* webpackChunkName: "about" */ '../views/Carte.vue'),
    meta: {
      title: 'Carte',
    },
  },
];

const router = createRouter({
  base: process.env.VUE_APP_PUBLICPATH,
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const DEFAULT_TITLE = process.env.VUE_APP_TITLE;
router.afterEach((to) => {
  nextTick(() => {
    document.title = to.meta.title ? `${DEFAULT_TITLE} - ${to.meta.title}` : DEFAULT_TITLE;
  });
});

export default router;
